.Home {
	justify-content: center;
	align-items: center;
	align-content: center;
}

.Header {
	/* position: absolute;
	margin-top: 1%;
	width: 50%; */
}

.homeHeaderImg {
	width: 75%;
}

.cham {
	/* z-index: 1;
	font-size: 6rem;
	color: #faebd7;
	position: absolute;
	width: 100%;
	top: 10%;
	left: 55%; */
}

.lala2pics {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.lala2pics:hover {
	cursor: pointer;
}

.lala2pics img {
	display: flex;
	width: 20%;
}
